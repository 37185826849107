import axios from "axios";
import { Global } from '../utils/general';

const API_URL = Global.url;

class ActiveService {
    getActivos() {
        const ruta = `${API_URL}/active`;
        return axios.get(ruta, {
        }).catch((error) => {
            return error;
        });
    }

    createActivo(body) {
        const ruta = `${API_URL}/active`;
        return axios.post(ruta, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).catch((error) => {
            return error;
        });
    }
}

export default new ActiveService();