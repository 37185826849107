import React from 'react'

const MainInputImage = ({ title, id = "", nameFile, allowedFileTypes = [], handleFilesChange, }) => {
    return (
        <fieldset className='fieldset'>
            <legend className='title-legend'>{title}</legend>
            <div className="input-group input-group-sm mb-3">
                <label
                    type="text"
                    className="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                >
                    {nameFile || "Seleccione un archivo"}
                </label>
                <span
                    className="input-group-text"
                    style={{ cursor: "pointer", }}
                    onClick={() => document.getElementById(`${id || ''}-image`).click()}
                    title='Cargar archivos'
                >📁</span>
                <input
                    id={`${id}-image`}
                    type="file"
                    accept={allowedFileTypes}
                    onChange={handleFilesChange}
                    style={{ display: 'none' }}
                />
            </div>
        </fieldset>
    )
}

export default MainInputImage