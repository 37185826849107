import axios from "axios";
import { Global } from '../utils/general';

const API_URL = Global.url;

class ExternalService {
    apiDNI(dni) {
        const ruta = `${API_URL}/apis-external/dni/${dni}`;
        return axios.get(ruta, {}).catch((error) => { return error; });
    }

    apiRUC(ruc) {
        const ruta = `${API_URL}/apis-external/ruc/${ruc}`;
        return axios.get(ruta, {}).catch((error) => { return error; });
    }
}

export default new ExternalService();