import React from 'react'
// import PropTypes from 'prop-types';
import './loader.css';

const Loader = ({ open = false, message = 'Cargando ...' }) => {
    return (
        open &&
        <div className="loader-overlay">
            <div className="loader-container">
                <span className="loader"></span>
                <p className='message'>{message}</p>
            </div>
        </div>
    )
}

// Loader.propTypes = {
//     message: PropTypes.string.isRequired,
// };

export default Loader