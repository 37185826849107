import React from 'react'

const MainSelect = ({ title, value, name, onChange, options, required = false, disabled = false, readOnly = false, }) => {
    return (
        <fieldset className='fieldset'>
            <legend className='title-legend'>{title}</legend>
            <select className="form-select form-select-sm" aria-label={`Seleccionar ${title}`}
                value={value || ''}
                name={name}
                onChange={onChange}
                style={{ borderRadius: '25px', }}

                required={required}
                disabled={disabled}
                readOnly={readOnly}
            >
                <option value={0}>Seleccione una opción</option>
                {options?.map((opt, idxOpt) => (
                    <option key={`${title}-${idxOpt}`} value={opt.id}>{opt.label}</option>
                ))}
            </select>
        </fieldset>
    )
}

export default MainSelect