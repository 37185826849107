import { Route, Routes, } from "react-router-dom";
import './App.css';
import './assets/css/style.css';
import FormBillingExternalServices from './app/pages/FormExternalServices/FormBillingExternalServices';
import ComparePlame from "./app/pages/ComparePlame/ComparePlame";
import OfficeFurnitureCatalog from "./app/pages/OfficeFurniture/OfficeFurnitureCatalog";
import OfficeFurnitureSale2 from "./app/pages/OfficeFurniture/OfficeFurnitureSale2";

function App() {
  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      <div style={{ padding: '10px' }}>
        <BodyWeb />
      </div>
    </div>
  );
}

function BodyWeb() {
  const pathInit = null;
  return (
    <div style={{ height: "100%", }}>
      <Routes>
        <Route path={`${(pathInit || '/')}`} element={<FormBillingExternalServices />}></Route>
        <Route path={`${(pathInit || '')}/compare-plame`} element={<ComparePlame />}></Route>
        <Route path={`${(pathInit || '')}/office-furniture-catalog`} element={<OfficeFurnitureCatalog />}></Route>
        <Route path={`${(pathInit || '')}/office-furniture-sale`} element={<OfficeFurnitureSale2 />}></Route>
      </Routes>
    </div>
  )
}

export default App;