import axios from "axios";
import { Global } from '../utils/general';

const API_URL = Global.url;

class PlameService {
    async compareFiles_0(body) {
        const ruta = `${API_URL}/compare-plame`;
        return axios.post(ruta, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: 'blob'
        }).catch((error) => {
            return error;
        });
    }

    async compareFiles(body) {
        const ruta = `${API_URL}/compare-plame`;
        return axios.post(ruta, body, {
        }).catch((error) => {
            return error;
        });
    }
}

export default new PlameService();