import axios from "axios";
import { Global } from '../utils/general';

const API_URL = Global.url;

class WorkerService {
    getTrabajador(documento) {
        const ruta = `${API_URL}/LP/worker/${documento}`;
        return axios.get(ruta, {}).catch((error) => { return error; });
    }
}

export default new WorkerService();