import React, { useState } from 'react'
// bootstrap
import { Carousel, } from 'react-bootstrap';
// Services
import ActiveService from '../../../services/active';
// Functions
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
// Components
import Loader from '../../components/Spinner/Loader';
import MainInput from '../../components/general/MainInput';
import MainInputImage from '../../components/general/MainInputImage';
import DynamicTitle from '../../../utils/DynamicTitle';

const newActivo = {
    precio: '',
    codigo: '',
    persona_id: '',
    numdoc: '',
    nombres: '',
    apellidos: '',
    telefono: '',
    email: '',
    descripcion: '',
}
const OfficeFurnitureCatalog = () => {
    const [openLoader, setOpenLoader] = useState(false);
    const [messageLoader, setMessageLoader] = useState(null);

    const [file, setFile] = useState(null);
    const [image, setImage] = useState([]);
    const [formData, setFormData] = useState(newActivo);

    const handleImageUpload = (event) => {
        setFile(event.target.files[0]);
        const files = Array.from(event.target.files);
        const imageUrls = files.map(file => URL.createObjectURL(file));
        setImage(imageUrls);
    };

    const sentForm = async (e) => {
        e.preventDefault();

        if (!formData?.precio) {
            Toast.fire({
                icon: 'warning',
                title: "Debe ingresar el precio"
            })
            return;
        }
        if (!formData?.codigo || formData?.codigo === '') {
            Toast.fire({
                icon: 'warning',
                title: "Debe ingresar el código"
            })
            return;
        }

        const dataI = new FormData();
        dataI.append("file", file);
        for (const [key, value] of Object.entries(formData)) {
            dataI.append(key, value);
        }

        try {
            setOpenLoader(true);
            setMessageLoader('Guardando información ...');

            const result = await ActiveService.createActivo(dataI);
            let icon = ''
            let title = ''
            if (result.status === CODES.CREATE_201) {
                cleanInfo();
                icon = 'success'
                title = result?.data?.message || "Activo guardado"
            } else {
                icon = 'error'
                title = result?.response?.data?.error || "Error al guardar"
            }
            Toast.fire({
                icon: icon,
                title: title
            })
        } catch (error) {
            console.log(`Error en getUsuarios: ${error}`);
        } finally {
            setOpenLoader(false);
            setMessageLoader(null);
        }
    }
    
    const cleanInfo = () => {
        setFormData(newActivo);
        setImage([]);
        setFile(null);
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
            <DynamicTitle title="Activos fijos - LP" />
            <div className="card" style={{ width: '30rem', margin: "1rem", padding: "0.5rem" }}>
                <MainInputImage
                    title={"Seleccione imagen"}
                    handleFilesChange={handleImageUpload}
                    allowedFileTypes={"image/*"}
                />
                <Carousel>
                    {image.map((src, index) => (
                        <Carousel.Item key={index}>
                            <img src={src} className="d-block w-100 card-img-top" alt={`Slide ${index}`} height={300} />
                        </Carousel.Item>
                    ))}
                </Carousel>
                <div className="card-body">
                    <form onSubmit={sentForm}>
                        <div className='row'>
                            <div className="col-sm-12">
                                <MainInput
                                    title={'Precio'}
                                    name={'precio'}
                                    type={'number'}
                                    value={formData.precio}
                                    onChange={(e) => { setFormData({ ...formData, precio: e.target.value }) }}
                                    required={true}
                                    step={'0.01'}
                                    min={'0'}
                                />
                            </div>
                            <div className="col-sm-12">
                                <MainInput
                                    title={'Código'}
                                    name={'codigo'}
                                    type={'text'}
                                    value={formData.codigo}
                                    onChange={(e) => { setFormData({ ...formData, codigo: e.target.value }) }}
                                    required={true}
                                />
                            </div>
                            <div className="col-sm-12">
                                <MainInput
                                    title={'Descripción'}
                                    name={'decripcion'}
                                    type={'text'}
                                    value={formData.descripcion}
                                    onChange={(e) => { setFormData({ ...formData, descripcion: e.target.value }) }}
                                />
                            </div>
                            <div className="col-md-12 mt-4 mb-2 d-flex justify-content-center">
                                <button type="submit" className="btn btn-primary">Subir</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Loader
                open={openLoader}
                message={messageLoader}
            />
        </div>
    )
}

export default OfficeFurnitureCatalog