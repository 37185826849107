import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, CardMedia, Slide, Dialog, DialogContent, DialogTitle, Divider, ButtonGroup, Button } from '@mui/material';
import ActiveService from '../../../services/active';
import SaleService from '../../../services/sale';
import WorkerService from '../../../services/worker';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
import MainInput from '../../components/general/MainInput';
import Loader from '../../components/Spinner/Loader';

const newSale = {
    precio: '',
    codigo: '',
    persona_id: '',
    activo_id: '',
    numdoc: '',
    nombres: '',
    apellidos: '',
    telefono: '',
    email: '',
};

const OfficeFurnitureSale2 = () => {
    const [openLoader, setOpenLoader] = useState(false);
    const [messageLoader, setMessageLoader] = useState(null);
    const [activos, setActivos] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({});
    const [filterType, setFilterType] = useState('todo');

    useEffect(() => {
        getActivos();
    }, []);

    const getActivos = async () => {
        try {
            setOpenLoader(true);
            setMessageLoader('Buscando activos ...');
            const result = await ActiveService.getActivos();
            setActivos(result.data);
        } catch (error) {
            console.log(`Error en getActivos: ${error}`);
        } finally {
            setOpenLoader(false);
            setMessageLoader(null);
        }
    };

    const searchPerson = async (activo) => {
        try {
            const resultLP = await WorkerService.getTrabajador(formData[activo.id_activo]?.numdoc);
            setFormData(prevData => ({
                ...prevData,
                [activo.id_activo]: {
                    ...prevData[activo.id_activo],
                    persona_id: resultLP?.data?.id_persona,
                    numdoc: resultLP?.data?.numdoc,
                    nombres: `${resultLP?.data?.nombres}`,
                    apellidos: `${resultLP?.data?.apePaterno} ${resultLP?.data?.apeMaterno}`,
                    telefono: resultLP?.data?.telefono,
                    email: resultLP?.data?.email,
                    precio: activo.precio,
                    activo_id: activo.id_activo,
                    codigo: activo.codigo,
                    descripcion: activo.descripcion,
                    id_ventaActivo: activo.id_ventaActivo,
                }
            }));
        } catch (error) {
            console.log(`Error en searchPerson: ${error}`);
        }
    };

    const sentForm = async (e, id_activo) => {
        e.preventDefault();

        const numdoc = formData[id_activo]?.numdoc || '';

        // Verifica si numdoc tiene 8 dígitos
        if (!/^\d{8}$/.test(numdoc)) {
            Toast.fire({
                icon: 'warning',
                title: "Debe ingresar un número de documento válido "
            });
            return;
        }

        if (!formData[id_activo]?.persona_id || formData[id_activo]?.persona_id === '' ||
            !formData[id_activo]?.numdoc || formData[id_activo]?.numdoc === ''
        ) {
            Toast.fire({
                icon: 'warning',
                title: "Debe pulsar la Lupa"
            });
            return;
        }



        
        if (!formData[id_activo]?.activo_id || formData[id_activo]?.activo_id === 0) {
            Toast.fire({
                icon: 'warning',
                title: "Debe seleccionar un activo valido"
            });
            return;
        }
        const dataI = new FormData();
        for (const [key, value] of Object.entries(formData[id_activo])) {
            dataI.append(key, value);
        }

        try {
            setOpenLoader(true);
            setMessageLoader('Guardando información ...');
            const result = await SaleService.createSale(dataI);
            let icon = '';
            let title = '';
            if (result.status === CODES.CREATE_201) {
                cleanInfo(id_activo);
                icon = 'success';
                title = result?.data?.message || "Activo guardado";
            } else {
                icon = 'error';
                title = result?.response?.data?.error || "Error al guardar";
            }
            Toast.fire({
                icon: icon,
                title: title
            });
        } catch (error) {
            console.log(`Error en getUsuarios: ${error}`);
        } finally {
            setOpenLoader(false);
            setMessageLoader(null);
        }
    };

    const cleanInfo = (id_activo) => {
        getActivos();
        setFormData(prevData => ({
            ...prevData,
            [id_activo]: newSale
        }));
        setSelectedProduct(null); // Oculta la imagen ampliada
    };

    const handlePedir = (id_activo) => {
        setSelectedProduct(id_activo);
        if (!formData[id_activo]) {
            setFormData(prevData => ({
                ...prevData,
                [id_activo]: newSale
            }));
        }
    };

    const handleCancelar = (id_activo) => {
        setSelectedProduct(null);
        setFormData(prevData => {
            const newData = { ...prevData };
            delete newData[id_activo];
            return newData;
        });
    };

    const handleOpenModal = (id_activo) => {
        setSelectedProduct(id_activo);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleFilterChange = (type) => {
        setFilterType(type);
    };

    const handleKeyPress = (event, info) => {
        if (event.key === 'Enter') {
            searchPerson(info);
        }
    };

    const renderActivos = () => {
        let filteredActivos = activos;
        if (filterType === 'disponible') {
            filteredActivos = activos.filter(producto => !producto.id_ventaActivo);
        } else if (filterType === 'vendidos') {
            filteredActivos = activos.filter(producto => producto.id_ventaActivo);
        }

        return filteredActivos.map((producto) => (
            <Grid key={producto.id_activo} item xs={12} sm={6} md={3}>
                <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <Card sx={{ maxWidth: 360, border: '2px solid rgba(0, 0, 0, 0.2)', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', position: 'relative' }}>
                        {selectedProduct !== producto.id_activo && (
                            <CardMedia
                                component="img"
                                height="200"
                                image={producto.ruta}
                                onClick={() => handleOpenModal(producto.id_activo)}
                                sx={{ cursor: 'pointer' }}
                            />
                        )}
                        {producto.id_ventaActivo && (
                            <div className="overlay-sales">
                                Vendido
                            </div>
                        )}
                        <Divider sx={{ marginTop: '0.1rem', marginBottom: '-0.5rem', borderColor: 'rgba(0, 0, 0, 0.5)', borderWidth: '2px', marginLeft: '0.8rem', marginRight: '0.8rem' }} />
                        <CardContent>
                            {selectedProduct !== producto.id_activo ? (
                                <>
                                    <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center' }}>
                                        {producto.descripcion || '--'}
                                    </Typography>
                                    <Typography variant="body2" color="blueviolet" fontWeight={'bold'} sx={{ textAlign: 'center', marginTop: '-0.5rem' }}>
                                        S/.{producto.precio}
                                        <br />
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', }}>
                                        Código: {producto.codigo}
                                    </Typography>
                                    {!producto.id_ventaActivo &&
                                        <div style={{ marginTop: '0.8rem', display: 'flex', justifyContent: 'center' }}>
                                            <button className="btn btn-primary" onClick={() => handlePedir(producto.id_activo)}>Solicitar</button>
                                        </div>
                                    }
                                </>
                            ) : (
                                <>
                                    <div className="col-sm-12 d-flex justify-content-evenly align-items-end">
                                        <div style={{ width: "100%" }}>
                                            <MainInput
                                                title={'Documento'}
                                                name={'numdoc'}
                                                type={'text'}
                                                value={formData[producto.id_activo]?.numdoc || ''}
                                                onChange={(e) => { setFormData({ ...formData, [producto.id_activo]: { ...formData[producto.id_activo], numdoc: e.target.value } }) }}
                                                handleKeyPress={(event) => handleKeyPress(event, producto)}
                                                required={true}
                                            />
                                        </div>
                                        <button
                                            type='button'
                                            className='btn btn-outline-dark btn-sm px-1 py--2'
                                            title='Buscar persona'
                                            onClick={() => searchPerson(producto)}
                                        >
                                            &#128269;
                                        </button>
                                    </div>
                                    <MainInput
                                        title={'Apellidos'}
                                        name={'apellidos'}
                                        type={'text'}
                                        value={formData[producto.id_activo]?.apellidos || ''}
                                        onChange={(e) => { setFormData({ ...formData, [producto.id_activo]: { ...formData[producto.id_activo], apellidos: e.target.value } }) }}
                                        readOnly={true}
                                        disabled={true}
                                        required={true}
                                    />
                                    <MainInput
                                        title={'Nombres'}
                                        name={'nombres'}
                                        type={'text'}
                                        value={formData[producto.id_activo]?.nombres || ''}
                                        onChange={(e) => { setFormData({ ...formData, [producto.id_activo]: { ...formData[producto.id_activo], nombres: e.target.value } }) }}
                                        readOnly={true}
                                        disabled={true}
                                        required={true}
                                    />
                                    <MainInput
                                        title={'Telefono'}
                                        name={'telefono'}
                                        type={'text'}
                                        value={formData[producto.id_activo]?.telefono || ''}
                                        onChange={(e) => { setFormData({ ...formData, [producto.id_activo]: { ...formData[producto.id_activo], telefono: e.target.value } }) }}
                                        required={true}
                                    />
                                    <MainInput
                                        title={'Correo electronico'}
                                        name={'email'}
                                        type={'email'}
                                        value={formData[producto.id_activo]?.email || ''}
                                        onChange={(e) => { setFormData({ ...formData, [producto.id_activo]: { ...formData[producto.id_activo], email: e.target.value } }) }}
                                        required={true}
                                    />
                                    {!producto.id_ventaActivo &&
                                        <div style={{ marginTop: '0.5rem', display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                                            <button className="btn btn-success" onClick={(e) => sentForm(e, producto.id_activo)}>Comprar</button>
                                            <button className="btn btn-danger" onClick={() => handleCancelar(producto.id_activo)}>Cancelar</button>
                                        </div>
                                    }
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Slide>
            </Grid>
        ));
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} md={8} sx={{ padding: "2% 8px" }} mb={2}>
                    <h3 style={{ paddingBottom: '0.5rem', textAlign: 'center' }}>Venta de artículos</h3>
                    <Box sx={{ flexGrow: 1, p: 1 }}>
                        <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{ justifyContent: 'center', marginBottom: '1rem' }}>
                            <Button onClick={() => handleFilterChange('todo')} variant={filterType === 'todo' ? 'contained' : 'outlined'}>Todos</Button>
                            <Button onClick={() => handleFilterChange('disponible')} variant={filterType === 'disponible' ? 'contained' : 'outlined'}>Disponibles</Button>
                            <Button onClick={() => handleFilterChange('vendidos')} variant={filterType === 'vendidos' ? 'contained' : 'outlined'}>Vendidos</Button>
                        </ButtonGroup>
                        <Grid container spacing={3} justifyContent="center" alignItems="center">
                            {renderActivos()}
                        </Grid>
                        {/* Modal para mostrar imagen ampliada */}
                        <Dialog open={openModal} onClose={handleCloseModal}>
                            <DialogTitle>Imagen Ampliada</DialogTitle>
                            <DialogContent>
                                {selectedProduct && (
                                    <Card>
                                        <CardMedia
                                            component="img"
                                            height="auto"
                                            image={activos.find((prod) => prod.id_activo === selectedProduct)?.ruta}
                                            sx={{ width: '100%' }}
                                        />
                                    </Card>
                                )}
                            </DialogContent>
                        </Dialog>
                    </Box>
                </Grid>
            </Grid>
            <Loader
                open={openLoader}
                message={messageLoader}
            />
        </Box>
    );
};

export default OfficeFurnitureSale2;
