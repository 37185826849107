import React, { useState } from 'react'
// images
import logo from '../../../assets/img/logo.png';
// services
import PlameService from '../../../services/plame';
// functions
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
// Components
import Loader from '../../components/Spinner/Loader';
import MainInputImage from '../../components/general/MainInputImage';
import DynamicTitle from '../../../utils/DynamicTitle';
// utils
import _ from 'lodash';
import Swal from 'sweetalert2';

const newCompare = { file1: { file: null, name: null }, file2: { file: null, name: null } }
const ComparePlame = () => {
    const [openLoader, setOpenLoader] = useState(false);
    const [messageLoader, setMessageLoader] = useState(null);

    /**/
    const containerStyles = {
        position: 'relative',
        width: '90%',
        maxWidth: '900px',
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        margin: '0 auto',
        marginTop: '25px',
        border: 'solid 1px #FF9CA6',
    };

    const formStyles = {
        // position: 'absolute',
        margin: '20px 20px 10px 20px',
        zIndex: 9999,
    };

    const allowedFileTypes = { file1: ['.csv'], file2: ['.pdf'] };
    const [infoCompare, setInfoCompare] = useState(newCompare);
    const [response, setResponse] = useState(null);
    const handleFileChange = (e, { key }) => {
        const files = e.target.files;
        let updData = _.cloneDeep(infoCompare);

        const file = files[0]
        const nameFile = file?.name;
        const fileExtension = nameFile?.split('.').pop().toLowerCase();
        if (!allowedFileTypes[key].includes(`.${fileExtension}`)) {
            Toast.fire({
                icon: 'warning',
                title: `Solo estan permitidos estos archivo </b> ${allowedFileTypes}`
            });
            return;
        } else {
            updData[key]["name"] = nameFile;
            updData[key]["file"] = file;
            setInfoCompare(updData);
        }
    };
    const compareFiles = async (e) => {
        e.preventDefault();

        setResponse(null);
        if (!infoCompare.file1.file || !infoCompare.file2.file) {
            Toast.fire({
                icon: 'warning',
                title: `Debe cargar ambos archivos`
            });
            return;
        }

        const resultSwal = await Swal.fire({
            title: "Seguro de iniciar la comparación de archivos",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#229954", // "#3085d6",
            confirmButtonText: "Si!",
            cancelButtonColor: "#d33",
            reverseButtons: true,
        });

        if (resultSwal.isConfirmed) {
            try {
                setOpenLoader(true);
                setMessageLoader('Comparando archivos ...');

                const dataI = new FormData();
                dataI.append("file1", infoCompare.file1.file);
                dataI.append("file2", infoCompare.file2.file);

                const result = await PlameService.compareFiles(dataI);
                if (result.status === CODES.SUCCESS_200) {
                    const data = result.data;
                    const datos = result.data.data;
                    if (datos.error_file) {
                        Toast.fire({
                            icon: 'error',
                            title: datos.error_file.message
                        });
                    } else {
                        // Decodificar el archivo base64
                        const byteCharacters = atob(data.file);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        const url = URL.createObjectURL(blob);

                        // Crear el enlace de descarga y hacer clic en él
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = 'ComparePlame.xlsx';
                        link.click();
                        URL.revokeObjectURL(url);

                        Toast.fire({
                            icon: 'success',
                            title: `Comparación finalizada!`
                        });
                        setInfoCompare(newCompare);
                    }
                    setResponse(datos)
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: `Error al hacer la comparación!`
                    });
                }
            } catch (error) {
                console.log(`Error en compareFiles: ${error}`);
            } finally {
                setOpenLoader(false);
                setMessageLoader(null);
            }
        }
    }

    return (
        <div style={containerStyles}>
            <DynamicTitle title="Comparación PLAME" />
            <div style={formStyles}>
                <div
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '0.2rem', }}>
                    <img src={logo} alt="Logo" width={150} />
                </div>
                <form onSubmit={compareFiles} >
                    <div className='row'>
                        <div className="col-md-6 mt-3">
                            <MainInputImage
                                title={"Archivo csv"}
                                id={"file1"}
                                nameFile={infoCompare.file1.name}
                                handleFilesChange={(e) => handleFileChange(e, { key: "file1" })}
                                allowedFileTypes={allowedFileTypes.file1}
                            />
                        </div>
                        <div className="col-md-6 mt-3">
                            <MainInputImage
                                title={"Archivo pdf"}
                                id={"file2"}
                                nameFile={infoCompare.file2.name}
                                handleFilesChange={(e) => handleFileChange(e, { key: "file2" })}
                                allowedFileTypes={allowedFileTypes.file2}
                            />
                        </div>
                        {response &&
                            <div className="col-md-12 mt-3">
                                <label>Total de documentos buscados: {+response?.total_registros}</label>
                                <br />
                                <label>Total de documentos encontrados: {+response?.total_estan}</label>
                                <br />
                                <label>Total de documentos no encontrados: {+response?.total_noestan}</label>
                                {response.total_error_busq > 0 &&
                                    <>
                                        <br />
                                        <label>Total de error de busqueda: {+response?.total_error_busq}</label>
                                    </>
                                }
                                {response.error_file &&
                                    <>
                                        <br />
                                        <label className="text-bg-danger text-white px-1 rounded">Error en el archivo: {response?.error_file?.error}</label>
                                    </>
                                }
                            </div>
                        }
                        <div className="col-md-12 mt-4 mb-2 d-flex justify-content-center">
                            <button type="submit" className="btn btn-primary">Comparar</button>
                        </div>
                    </div>
                </form>
            </div>
            <Loader
                open={openLoader}
                message={messageLoader}
            />
        </div>
    )
}

export default ComparePlame