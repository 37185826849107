import axios from "axios";
import { Global } from '../utils/general';

const API_URL = Global.url;

class CompanyService {
    getEmpresas() {
        const ruta = `${API_URL}/LP/companies`;
        return axios.get(ruta, {}).catch((error) => { return error; });
    }
}

export default new CompanyService();