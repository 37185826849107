import React from 'react'

const MainInput = (
    {
        title, value, name, handleKeyPress, onChange, type = 'text', required = false, disabled = false, readOnly = false,
        step = null, min = null, max = null,
    }
) => {
    return (
        <fieldset className='fieldset'>
            <legend className='title-legend'>{title}</legend>
            <input className="form-control form-control-sm" placeholder={`Ingrese ${title}`}
                value={value || ''}
                name={name}
                type={type}
                onKeyPress={handleKeyPress}
                onChange={onChange}
                required={required}
                disabled={disabled}
                readOnly={readOnly}
                step={step || ''}
                min={min || ''}
                max={max || ''}
                style={{ borderRadius: '25px', }}
            />
        </fieldset>
    )
}

export default MainInput