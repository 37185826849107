import axios from "axios";
import { Global } from '../utils/general';

const API_URL = Global.url;

class ServiceService {
    createServicio(body) {
        const ruta = `${API_URL}/LP/service`;
        return axios.post(ruta, body, {}).catch((error) => { return error; });
    }
}

export default new ServiceService();