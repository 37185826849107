import axios from "axios";
import { Global } from '../utils/general';

const API_URL = Global.url;

class GeneralService {
    getMoneda() {
        const ruta = `${API_URL}/LP/general/moneda`;
        return axios.get(ruta, {}).catch((error) => { return error; });
    }

    getCondicionPago() {
        const ruta = `${API_URL}/LP/general/payment-condition`;
        return axios.get(ruta, {}).catch((error) => { return error; });
    }

    getTipoComprobante() {
        const ruta = `${API_URL}/LP/general/voucher-type`;
        return axios.get(ruta, {}).catch((error) => { return error; });
    }
}

export default new GeneralService();