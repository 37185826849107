import axios from "axios";
import { Global } from '../utils/general';

const API_URL = Global.url;

class SaleService {
    createSale(body) {
        const ruta = `${API_URL}/active/sale`;
        return axios.post(ruta, body, {
        }).catch((error) => {
            return error;
        });
    }

    getVentas(dateStart,dateEnd) {
        const ruta = `${API_URL}/active/sale?date1=${dateStart}&date2=${dateEnd}`;
        return axios.get(ruta, {
        }).catch((error) => {
            return error;
        });
    }

    exportSalesExcel = function (date1, date2) {
        let ruta = `${API_URL}/report/sales/${date1}/${date2}`;
        return ruta;
    }
}

export default new SaleService();